<script>
import { isWebLink } from "@/lib/utils"
import determineTextColor from "@/lib/determine-text-color"

export default {
  functional: true,

  props: {
    to: { type: [String, Object] },
    flat: { type: Boolean, default: true },
    raised: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    overriddenColor: { type: String, default: null },
    danger: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    ghost: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xSmall: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    active: { type: Boolean, default: false }
  },

  render(h, { props, slots, data, listeners }) {
    // TODO: Update to use determineTextColor for button color
    const { to } = props
    const classList = ["v-button", data.staticClass, data.class]
    const isLink = !!to
    const attrs = data.attrs || {}

    if (props.round) classList.push("--round")
    if (props.rounded) classList.push("--rounded")
    if ((props.flat || props.ghost) && !props.raised) classList.push("--flat")
    if (props.block) classList.push("--block")
    if (props.ghost) classList.push("--ghost")
    if (props.light) classList.push("--light")
    if (props.dark) classList.push("--dark")
    if (props.small) classList.push("--small")
    if (props.xSmall) classList.push("--x-small")
    if (props.large) classList.push("--large")
    if (props.raised) classList.push("--raised")
    if (props.outlined) classList.push("--outlined")
    if (props.secondary) classList.push("--secondary")
    if (props.danger) classList.push("--danger")
    if (props.warning) classList.push("--warning")
    if (props.isLoading) classList.push("--loading")
    if (props.active) classList.push("--active")

    const buttonContent = [
      slots().default,
      props.isLoading && (
        <span class="button__loader">
          <v-loader></v-loader>
        </span>
      )
    ]

    let style = {}

    if (props.overriddenColor) {
      // Override color
      style = {
        backgroundColor: props.overriddenColor,
        color: determineTextColor(props.overriddenColor)
      }
    }
    // else if (for primary/light) {}

    if (isLink && isWebLink(to)) {
      return (
        <a
          href={to}
          target="_blank"
          style={style}
          class={classList.join(" ")}
          on={listeners}
          disabled={props.disabled || props.isLoading}
          {...{ attrs }}
        >
          {...buttonContent}
        </a>
      )
    } else if (isLink) {
      return (
        <v-link to={to}>
          <button
            style={style}
            class={classList.join(" ")}
            on={listeners}
            disabled={props.disabled || props.isLoading}
            {...{ attrs }}
          >
            {...buttonContent}
          </button>
        </v-link>
      )
    } else if (data.attrs && data.attrs.type === "submit") {
      return (
        <button
          style={style}
          class={classList.join(" ")}
          on={listeners}
          modifier={props.modifier}
          type="submit"
          disabled={props.disabled || props.isLoading}
          {...{ attrs }}
        >
          {...buttonContent}
        </button>
      )
    } else {
      return (
        <button
          style={style}
          on={listeners}
          disabled={props.disabled || props.isLoading}
          class={classList.join(" ")}
          {...{ attrs }}
        >
          {...buttonContent}
        </button>
      )
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.v-button {
  @include clickable;

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  background-clip: padding-box;
  cursor: default;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-decoration: none;
  font-size: $font-size--base;
  font-family: var(--body-font-stack);
  line-height: $font-size--large;
  font-weight: $font-weight--semi-bold;
  color: white; // Ensure text is white during dark mode
  color: var(--button-text-color-hex);
  vertical-align: middle;
  min-height: $size--8;
  padding: $size--4 $size--6;
  margin: 0;
  text-align: center;
  border-radius: 6px; // Fallback
  border-radius: var(--input-border-radius);
  background: #5c29d3; // Fallback
  background: var(--primary-brand-color-hex);
  box-shadow: $box-shadow--small;
  border: none;
  transition: all 0.2s ease;

  &.--loading {
    color: transparent;
  }

  &.--secondary {
    background-color: $color-ui--grey-20;
    color: $color-ui--grey-80;

    &.--loading {
      color: transparent !important;
    }

    &:active,
    &:focus {
      background: $color-ui--grey-30;
    }

    &[disabled],
    &:disabled {
      opacity: 0.6;
    }

    &.--outlined,
    &.--ghost {
      color: $color-ui--grey-80;
      background: transparent;

      &.--loading {
        color: transparent;

        .button__loader .v-loader {
          @include loader-color($color-ui--grey-60);
        }
      }
    }

    &.--ghost {
      border-color: transparent;
    }

    &.--outlined {
      border: 2px solid $color-ui--grey-20;
    }
  }

  &.--light {
    color: $color-fixed--grey-80;
    background: $color-fixed--white;

    &.--loading {
      color: transparent;
    }

    &:active,
    &:focus {
      background: $color-fixed--grey-10;
    }

    &[disabled],
    &:disabled {
      opacity: 0.6;
    }

    &.--outlined,
    &.--ghost {
      color: $color-fixed--white;
      background: transparent;

      &.--loading {
        color: transparent;

        .button__loader .v-loader {
          @include loader-color($color-ui--grey-60);
        }
      }
    }

    &.--ghost {
      border-color: transparent;
    }

    &.--outlined {
      border: 2px solid $color-ui--grey-20;
    }
  }

  &.--dark {
    color: $color-fixed--white;
    background: $color-fixed--black;

    &:active,
    &:focus {
      background: $color-fixed--black;
    }
  }

  &.--round,
  &.--rounded {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $size--3 $size--5;

    .v-icon {
      position: relative;
      top: 1px;
    }
  }

  &.--round {
    width: $size--9;
    min-width: $size--9;
    max-width: $size--9;
    height: $size--9;
    min-height: $size--9;
    max-height: $size--9;
    padding: $size--2 $size--3;

    &.--large {
      width: $size--10;
      height: $size--10;
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }

  &.--flat {
    box-shadow: none;
  }

  &.--outlined,
  &.--ghost {
    background: transparent;
    color: var(--primary-brand-color-hex);
    border: 2px solid var(--primary-brand-color-hex);

    &.--loading {
      color: transparent;

      .button__loader .v-loader {
        @include loader-color(var(--primary-brand-color-hex));
      }
    }

    &:active,
    &:focus {
      background: transparent;
    }

    &:hover {
      background: $color-ui--grey-10;
    }
  }

  &.--ghost {
    border: none;
  }

  &:active {
    opacity: 1;
    background: var(--primary-brand-color-hex);
  }

  &.--block {
    display: block;
    width: 100%;
    margin: 0;
  }

  &.--small {
    line-height: $font-size--small;
    padding: $size--2 $size--4;
    font-size: $font-size--small;
    font-weight: 600;

    .button__loader .progress {
      height: $size--5;
      width: $size--5;
    }

    &.--round {
      height: $size--8;
      min-height: $size--8;
      max-height: $size--8;
      width: $size--8;
      min-width: $size--8;
      max-width: $size--8;
    }

    .v-icon {
      top: 1px;
    }
  }

  &.--x-small {
    line-height: $font-size--x-small;
    padding: $size--1 $size--2;
    font-size: $font-size--x-small;
    min-height: $size--4;

    &.--round {
      height: $size--5;
      min-height: $size--5;
      max-height: $size--5;
      width: $size--5;
      min-width: $size--5;
      max-width: $size--5;

      .v-icon {
        top: 0px;
      }
    }

    .v-icon {
      top: 0;
    }
  }

  &.--large {
    padding: $size--5 $size--8;
  }

  &.--raised {
    box-shadow: $box-shadow--large;
  }

  .v-icon {
    transform: scale(1.25);
    position: relative;
    top: 2px;
  }

  &:hover {
    cursor: pointer;
  }
}

.button__loader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.2s ease;

  .v-loader {
    @include loader-color($color-ui--white);

    height: $size--6;
    width: $size--6;
  }
}
</style>
